<template>
	<el-dialog
	    width="600px"
		@open="open"
		@close="close"
	  >
		<div class="a">
			<div class="a-title">上传支付凭证</div>
			<div class="a-price">订单金额：<span>{{orderInfo.totalAmount}}</span>元</div>
			<div class="a-content">
				<div class="a-left">
					<el-image
						:src="img"
						style="width: 200px;height: 200px;object-fit: cover;"
						></el-image>
				</div>
				<div class="a-right">
					<el-upload
					    class="upload-demo"
					    :action="getAction"
						:headers="getHeaders"
						:show-file-list="true"
						:on-success="onSuccess"
						list-type="picture-card"
						:limit="1"
						:before-upload="beforeAvatarUpload"
						:on-remove="deleteFile"
						:file-list="fileList"
					  >
						<el-button size="small" type="text">选择凭证</el-button>
					</el-upload>
					<span>"如已付款，请上传一张支付凭证"</span>
				</div>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" size="mini" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import request from "@/common/js/request.js"
	import api from "@/common/js/api.js"
	import { mapState, mapActions } from "vuex"
	import { uploadFileOnSuccess } from "@/common/js/uploadFile.js"
	import { ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				fileList:[],
				ids:[],
				img:'',
			}
		},
		computed:{
			...mapState([
				'token', //获取token
			]),
			getAction(){ //上传文件地址
				return request.defaults.baseURL + api.uploadFile + '?fileType=CUSTOMER';
			},
			getHeaders(){ //请求头部
				return {
					'Authorization':this.token || null,
				}
			}
		},
		props:['orderInfo'],
		methods:{
			...mapActions('mysaleMudule',[
				'userGetUserSet',  //获取收款二维码
			]),
			close(){ //关闭弹框
				this.fileList = [];
				this.$emit('update:modelValue', false);
			},
			confirm(){ //确认的点击事件
				if(this.ids.length){
					this.$emit('confirm',this.ids);
				}else{
					ElMessage({
						type:'warning',
						message:'请选择上传文件'
					})
				}
			},
			beforeAvatarUpload(file){ //判断文件大小
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isLt3M) {
					this.$message.error('单个文件大小不能超过3MB!');
				}
				return isLt3M;
			},
			onSuccess(res, file, fileList){ //上传成功的回调
				uploadFileOnSuccess(res, file, fileList).then(res => {
					this.fileList = [...fileList];
				}).catch(res => {})
			},
			deleteFile(file, fileList){ //删除文件的回调
				this.fileList = [...fileList];
			},
			getCode(){
				this.userGetUserSet({
					setTpCd:'COLLECTION_QR_CODE',
					sellerId:this.orderInfo.sellerId,
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						if(data.files){
							data.files = JSON.parse(data.files);
							if(data.files.length){
								this.img = data.files[0].url;
							}
						}
					}
				})
			},
			open(){
				this.getCode();
			}
		},
		watch:{
			fileList(v){
				var arr = [];
				v.map(item => {
					if(item.fileId){
						arr.push(item.fileId);
					}else if(item.response.code === '0'){
						arr.push(item.response.data.fileId);
					}
				})
				this.ids = arr;
			}
		}
	}
</script>

<style scoped lang="less">
	.a{
		font-size: 14px;
		padding: 20px;
		.a-title{
			font-weight: bold;
			padding: 0 20px 20px 0;
		}
		.a-footer{
			display: flex;
			justify-content: center;
		}
		.a-price{
			color: #D84146;
			span{
				font-weight: bold;
				font-size: 18px;
				margin-right: 5px;
			}
		}
		.a-content{
			padding: 10px 20px 20px 20px;
			display: flex;
			justify-content: center;
			.a-left{
				display: flex;
				flex-direction: column;
				align-items: center;
				span{
					padding-bottom: 20px;
					color: #666666;
					font-weight: bold;
				}
			}
			.a-left:nth-child(1){
				margin-right: 60px;
			}
			.a-right{
				display: flex;
				flex-direction: column;
				align-items: center;
				span{
					padding-top: 30px;
					font-weight: bold;
				}
			}
		}
	}
</style>
