<template>
	<lida-table
		:pagination="false" 
		:cutHeight="0" 
		:data="data"
		border
		ref="table">
			<lida-table-column label="产品信息" fixed>
				<template #default="scope">
					<div class="g-info">
						<div class="g-icon">
							<img :src="scope.row.src"/>
						</div>
						<div class="g-right">
							<div class="g-r-title">
								{{scope.row.productName}}
								<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
							</div>
							<div class="g-r-data">
								{{scope.row.barCode || '无'}}
								<span>|</span>
								{{scope.row.seriesName || '无'}}
								<span>|</span>
								{{scope.row.skuNumber || '无'}}
							</div>
						</div>
					</div>
				</template>
			</lida-table-column>
			<lida-table-column label="订货号" prop="articleNo" />
			<lida-table-column label="面价" prop="guidePrice" />
			<lida-table-column label="报价数量" prop="quoteQty" />
			<lida-table-column label="采购数量" prop="purchaseQty" />
			<lida-table-column label="采购单价" prop="purchasePrice" />
			<lida-table-column label="发货地区" prop="shipArea" />
			<lida-table-column label="渠道" prop="channel" />
			<lida-table-column label="是否过保" prop="isExpire" />
	</lida-table>
</template>

<script>
	export default{
		data(){
			return{
				data:[],  //采购列表数据
			}
		},
		props:['info','orderInfo','inquiryId'],
		methods:{
			
		},
		created(){
			this.data = this.orderInfo.documentItemResultVOList;
		},
		watch:{
			orderInfo(v){
				this.data = v.documentItemResultVOList;
			}
		}
	}
</script>

<style scoped lang="less">
</style>
